.nav.ui.menu.inverted{
    z-index: 888;
}

.ui.menu.inverted.nav{
    background-color: #505e67;
    /*padding: 0;*/
    min-height: 60px;
}


.ui.secondary.menu .item{
    /*padding: 0 10px;*/
    /*margin: 0;*/
}

.ui.nav.menu .item .ui.button{
  padding: 3px;
  background-color: #3b464d;
  box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
}

.ui.nav.menu .item .ui.button:hover{
  background-color: #748a97;
}

.menu-btn{
  position: fixed;
  top: 1px;
  left: 36%;
  width: 110px;
}

.item.spotlight-logo{
  position: relative;
  left: -25px;
}

.item.njtv-logo{
  position: relative;
  left: 25px;
}

.shade {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 666;
}


.modal {
  z-index: 777;
  position: fixed;
  top: 0;
  left: 3%;
  width: 90%;
  min-height: 340px;
  display: flex;
  flex-flow: column;
  background: white;
  background-color: #3b464d;
  border-radius: 10px;
  list-style: none;
  padding: 10px 5px 40px 5px;
  text-align: center;
}

.modal .modal-item{
    background-color: #aaa;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    color: black;
    z-index: 999;
    text-align: left;
}

.modal .modal-item a{
  color: #fff;
  text-shadow: 3px 3px 3px rgba(0,0,0,0.3);
  text-transform: uppercase;
  font-size: 1.5em;
  cursor: pointer;
}

.modal .close-btn{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 5px;
    padding: 8px;
    outline: none;
    cursor: pointer;
    background-color: #748a97;
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
  .modal .modal-item{
     padding: 4px;
  }

  .modal {
    left: 20%;
    width: 60%;
    padding: 10px 15px 40px 15px;
  }

  .menu-btn{
    left: 42%;
    width: 140px;
  }
  .ui.menu.inverted.nav{
      min-height: 80px;
  }

}

@media only screen and (min-width: 868px) {
  /* For desktop: */
  .modal .modal-item{
    padding: 8px;
  }
  .modal {
    left: 20%;
    width: 60%;
      padding: 10px 15px 40px 15px;
  }
  .menu-btn{
    left: 43%;
    width: 150px;
  }
  .ui.menu.inverted.nav{
      min-height: 80px;
  }

  .item.spotlight-logo{
    left: -25px;
  }

  .item.njtv-logo{
    left: 25px;
  }
}


@media only screen and (min-width: 1168px) {
  /* For large screens: */
  .modal .modal-item{
    padding: 8px;
  }
  .modal {
    left: 33%;
    width: 33%;
      padding: 10px 15px 40px 15px;
  }
  .menu-btn{
    left: 45%;
    width: 160px;
  }
  .ui.menu.inverted.nav{
      min-height: 80px;
  }

  .item.spotlight-logo{
    left: 0px;
  }

  .item.njtv-logo{
    left: 0px;
  }

}
