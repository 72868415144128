.background-vid{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../images/TitleImage.jpg');
}

.background-overlay{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-image: url('../../images/TitleGradientStrip.png');
  background-repeat: repeat-x;
}
