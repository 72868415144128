.credits *{
    font-size: 1em;
    text-align: center;
}
.ui.segment.credits{
    background-color: rgba(0,0,0,.5);
}

.credits .ui.segment.special-thanks{
    color: white;
}

.credits .ui.image.credits-header{
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.credit-comp .card.credit-item{
    background-color: rgba(0,0,0,1);
    box-shadow: none;
    border-top: 1px solid rgba(255,255,255,0.4);
    padding: 0;
    margin: 0;
}

.credit-comp .card.credit-item .content{
    padding: 2px;
    margin: 0px;
}

.credit-comp .card.credit-item .header.credit-header{
    color: rgba(255, 255, 255, 1);
    padding: 3px 1px;
    text-align: center;
    position: absolute;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    width: 97%;
    z-index: 777;
}

.credit-comp .card.credit-item .ui.image.body-text{
    color: rgba(255, 255, 255, 1);
    font-size: .8em;
    line-height: 1.7em;
    text-transform: uppercase;
    display: block;
    margin: 5px 5px;
    text-align: left;
}


.byline {
    padding: 0;
    display: flex;
    width: 60%;
    margin: 30px auto;
    flex-wrap: wrap;
    text-shadow: 3px 3px 3px rgba(0,0,0,0.3);
    list-style: none;
    font-size: 1.2rem;
    line-height: 1.3em;
}

.byline-item{
    flex-basis: 50%;
    width: 50%;
    color: white;
}
