.blog-header{
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
    padding-top: 100px;
}

.blog-header-content{
    padding: 0 15%;
}

.blog-header .ui.menu.inverted{
    background-color: #505e67;
}

.blog-subheader{
    /*padding-top: 80px;*/
    font-size: .6em;
    font-weight: bold;
    line-height: 1.3em;
    background-color: #505e67;
}


 /* For mobile: */
.logo{
    padding: 30% 0 3% 0;
    margin: 0;
}


@media only screen and (min-width: 600px) {
    /* For tablets: */
    .logo{
        padding: 20% 10% 3% 10%;
        margin: 0;
    }
    .blog-subheader{
        font-size: .8em;
    }
}

@media only screen and (min-width: 868px) {
  /* For desktop: */
    .logo{
        padding: 2% 0;
        margin: 0;
    }
    .blog-subheader{
        padding-top: 80px;
        font-size: 1em;
    }
}

.btn{
    padding: 15px;
    font-size: 1.3rem;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    /*box-shadow: 5px 5px 5px rgba(0,0,0,0.4);*/
}

.toc-btn{
    background-color: rgba(225, 225, 225, 0.4);
    color: white;
}

.downArrow{
    position: relative;
    color: white;
    padding: 4% 0 10% 0;
}

.bounce {
    animation: bounce 3s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(-10px);
  }
  40% {
    transform: translateY(-12px);
  }
  60% {
    transform: translateY(-10px);
  }
}
