.ui.segment.inverted.custom-tooltip{
    background-color: rgba(80,94,103,0.8);
}

.custom-tooltip h4, .custom-tooltip p{
    padding: 2px;
    font-size: 1em;
    width: 250px;
    text-transform: capitalize;
    font-weight: bold;
    text-shadow: 3px 3px 13px rgba(0,0,0,0.6)
}
