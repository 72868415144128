.chart-container {
    background-position: left bottom;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
}

.chart {
    /*background: rgb(0,0,0, 0.4);*/
    /*background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.5746673669467788) 68%, rgba(255,255,255,0) 100%);*/
}
