.banner{
    z-index: 555;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*margin-bottom: 60px;*/
    /*padding-top: 4%;*/
}

.banner .banner-content{
  margin: 0 10%;
  /*margin: 0;*/
  /*margin: 10px;*/
  /*padding: 8px;*/
}

.banner .banner-content .ui.header.banner-text{
    font-size: 2.4em;
    padding: 8px 3px;
 }

.banner-text.ui.header{
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  border: 3px solid white;
  padding: 5px;
  text-align: center;
  font-size: 3.5em;
  font-weight: normal;
  color: white;
  /*text-transform: uppercase;*/
  text-shadow: 2px 2px 8px rgba(0,0,0,0.2);
  font-family: 'Montserrat', sans-serif;
}

.ui.header.text-block-title{
  font-size: 2em;
}
.parallax-outer{
  z-index: -10;
}


@media only screen and (min-width: 600px) {
    /* For tablets: */
  .banner .banner-content .ui.header.banner-text{
      font-size: 2.4em;
      padding: 8px 3px;
   }
}

@media only screen and (min-width: 868px) {

  /* For desktop: */
  .banner .banner-content{
    margin: 0 20%;
  }

  .banner .banner-content .ui.header.banner-text{
      font-size: 2.8em;
      padding: 8px 3px;
  }
}
