body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.1em;
}

p{
  line-height: 1.7em;
  font-size: 1em;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.float-right{
  float: none;
  padding: 0px;
  margin-bottom: 10px;
}

.float-left{
  float: none;
  padding: 0px;
  margin-bottom: 10px;
}

.float-center{
  clear: both;
  text-align: center;
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
  .float-right{
    float: right;
    width: 40%;
    padding-left: 10px;
  }

  .float-left{
    float: none;
    padding-right: 0;
  }
}

@media only screen and (min-width: 868px) {

  /* For desktop: */
  .float-right{
    float: right;
    width: 40%;
    margin-left: 10px;
  }

  .float-left{
    float: left;
    width: 40%;
    margin-right: 20px;
  }
}
