.section{
    background-color: white;
}

.ui.grid>.row.section{
    padding-top: 0;
}
.ui.grid.section-container .section-content{
    margin: 30px 0 0 0;
    padding-left: 0;
    padding-right: 0;
}

.ui.grid.section-grid{
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 10px;
}

.ui.grid.section-grid > .column{
    display: block;
    background-color: white;
    margin: 0;
    margin-bottom: 5px;
    padding-right: 0;
}


#section_1.section h2.ui.header{
    font-size: 1.3em;
}


@media only screen and (min-width: 600px) {
    /* For tablets: */
    #section_1.section h2.ui.header{
        font-size: 2em;
        padding: 20px 50px 0px 50px;
    }
}

@media only screen and (min-width: 1450px) {
    /* For desktop: */
    #section_1.section h2.ui.header{
        font-size: 3em;
        padding: 20px 150px 0px 150px;
    }
}
