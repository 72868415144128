.youtube-video-wrapper{
    padding: 0 0 10px 0;
    /*border: thin solid red;*/
    z-index: 555;
    margin-bottom: 20px;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}

.youtube-video-element{
    background-color: black;
    border: 6px solid black;
    border-radius: 5px;
}
