.image-wrapper {
    padding: 10px;
    margin: 10px;
    border-radius: 3px;
    background-color: #eaeaea;
    border: thin solid #ccc5c5;
}

.image-wrapper .image-ele{
  width: 100%;
}


@media only screen and (min-width: 600px) {
     /*For tablets:*/
}

@media only screen and (min-width: 868px) {
  /* For desktop:*/
  .image-wrapper {
    /*width: 350px !Important;*/
  }
}
